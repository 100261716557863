import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { GatewayBase } from "./gateway-base";
import { GLI_API_CONFIGURATION } from "./gli-api-configuration";
import { RegistrationConfiguration, EmrProvider } from "./models";

@Injectable({ providedIn: "root" })
export class GliConfigurationService extends GatewayBase {
    private _configuration = inject(GLI_API_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    getRegistrationConfiguration(): Observable<RegistrationConfiguration> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<RegistrationConfiguration>(`${url}/api/config/registration`)
            )
        );
    }

    getEmrProviders(): Observable<EmrProvider[]> {
        return this._getUrl().pipe(
            switchMap(url => this._httpClient.get<EmrProvider[]>(`${url}/api/config/emr/providers`))
        );
    }

    private _getUrl(): Observable<string> {
        return from(this._configuration).pipe(map(config => config.url));
    }
}
