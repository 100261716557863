import { Injectable, OnDestroy, inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil, shareReplay } from "rxjs/operators";

import { AppUser, GliCpService } from "gli-api";

@Injectable({
    providedIn: "root"
})
export class UmAppState implements OnDestroy {
    private _cpService = inject(GliCpService);

    private readonly _user$: Observable<AppUser>;
    private readonly _destroyed$ = new Subject<void>();

    constructor() {
        this._user$ = this._cpService.getSelf().pipe(takeUntil(this._destroyed$), shareReplay(1));
    }

    get currentCP(): Observable<AppUser> {
        return this._user$;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
