import { Directive, OnDestroy, inject } from "@angular/core";
import { Subject, MonoTypeOperatorFunction } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class PageComponentBase implements OnDestroy {
    protected _translationService = inject(LgTranslateService);

    protected readonly _destroyed$ = new Subject<void>();

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil<T>(this._destroyed$);
    }
}
