import { Injectable, inject } from "@angular/core";
import {
    ApplicationInsightsCustomData,
    IApplicationInsightsConfiguration
} from "@logex/framework/lg-application";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { BehaviorSubject } from "rxjs";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class ApplicationInsightsConfiguration implements IApplicationInsightsConfiguration {
    private _configService = inject(ConfigService);

    autoInit = true;
    instrumentationKey: string;
    correlationHeaderExcludedDomains: string[];
    readonly userId = new BehaviorSubject<string | null>(null);

    constructor() {
        this._configService.configurationPromise.then(value => {
            this.instrumentationKey = value.applicationInsights?.instrumentationKey;
            this.correlationHeaderExcludedDomains =
                value.applicationInsights?.correlationHeaderExcludedDomains;
        });
    }

    doNotDoAiTracking(): boolean {
        return !this.instrumentationKey;
    }

    setUserID(id: string): void {
        this.userId.next(id);
    }

    customData(): ICustomProperties {
        return {
            [ApplicationInsightsCustomData.ApplicationInstance]:
                this._configService.configuration.instance,
            [ApplicationInsightsCustomData.Environment]:
                this._configService.configuration.environment,
            [ApplicationInsightsCustomData.Registry]: "gli"
        };
    }
}
