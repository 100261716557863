import { ProfessionalLinkLinkStatus } from "./models";

export type EmailVerifiedResponseCanResend = "yes" | "no" | "later" | null;
export interface EmailVerifiedResponse {
    result: "no" | "verified" | "pending" | "blocked";
    when: string | null;
    canResend: EmailVerifiedResponseCanResend;
}

export type SendVerificationEmailResult =
    | "already-verified"
    | "cannot-verify"
    | "failed"
    | "ok"
    | "blocked";

export interface VerifyEmailResult {
    ok: boolean;
    email: string | undefined;
}

export interface RegisterAsCpArgs {
    email: string;
    firstName: string;
    lastName: string;
    gender: number;
    position: string | null;
    phone: string;
    password: string | null;
}

export interface RegisterAsCpResult {
    result: "blocked" | "removed" | "already-cp" | "ok";
    changePasswordUrl: string | null;
}

export type CpStatusResult = "yes" | "no" | "blocked" | "removed";

export interface RegisterAsStandaloneArgs {
    contactPerson: RegisterAsCpArgs;
    personalAgbCode: string;
    organisationAgbCode: string;
    specialArrangement: boolean;
    organisationName: string;
    agreementsName: string;
    organisationLine1: string;
    organisationZip: string;
    organisationCity: string;
    confirmationEmail: string;
    importHistory: boolean;
    emrProviderId: number;
    surveyConnectionName: string | null;
}

export interface RegisterAsStandaloneResult {
    result:
        | "blocked"
        | "removed"
        | "organisation-registered"
        | "invalid-personal-code"
        | "invalid-organisation-code"
        | "no-relation"
        | "already-cp"
        | "ok";
    changePasswordUrl: string | null;
    autoConfirmed: boolean | null;
}

export interface HpTokenResponseContactPerson {
    name: string;
    phone: string;
    email: string;
}

export interface GetHpOkTokenResponse {
    result: "ok";
    agbCode: string;
    serviceProviderName: string;
    serviceProviderAddress: string;
    serviceProviderZip: string;
    serviceProviderCity: string;
    contactPersons: HpTokenResponseContactPerson[];
}

export type GetHpTokenResponse =
    | {
          result: "invalid" | "expired";
      }
    | GetHpOkTokenResponse;

export type GetHpTokenStatusResponse = {
    result: "invalid" | "expired" | "ok" | "exists";
    status: ProfessionalLinkLinkStatus | null;
};

export interface RegisterAsHpArgs extends RegisterAsCpArgs {
    personalAgbCode: string;
    importHistory: boolean;
}

export interface RegisterAsHpResponse {
    result:
        | "blocked"
        | "invalid-personal-code"
        | "ok"
        | "invalid"
        | "expired"
        | "exists"
        | "no-relation"
        | "agb-exists-in-organisation";
    status: ProfessionalLinkLinkStatus | null;
    changePasswordUrl: string | null;
}

export interface GetCpConfirmationStatusResponse {
    status: "invalid" | "expired" | "awaiting" | "confirmed" | "refused";
    organisationName: string | null;
    agbCode: string | null;
    contactPersonName: string | null;
    contactPersonEmail: string | null;
    contactPersonPhone: string | null;
}
