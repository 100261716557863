import { Dictionary } from "lodash";
import { LOCALE_ID, Injectable, inject } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { ConfigService } from "./config.service";
import { loadScript } from "@logex/framework/utilities";

const LANGUAGE_STORAGE_KEY = "preferedLanguage";

@Injectable()
export class UmLocalizationSettings implements LgLocalizationSettings {
    private _config = inject(ConfigService);
    private _lgLoaderService = inject(LgLoaderService);
    private _locale = inject(LOCALE_ID);

    public bootstrapDone: Promise<void>;

    readonly languages: _.Dictionary<any[]> = {};

    private _preferredLanguage;
    private setReady: () => void;

    constructor() {
        this.bootstrapDone = new Promise(resolve => {
            this.setReady = resolve;
        });
    }

    get fallbackLanguage(): string {
        return "en-GB";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return this._config.isLocalOrTestEnvironment
            ? ["nl-NL", "en-GB", "zu-ZA"]
            : ["nl-NL", "en-GB"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    get userPreferedLanguage(): string {
        return localStorage.getItem(LANGUAGE_STORAGE_KEY);
    }

    init(): void {
        if (this._config.isLocalOrTestEnvironment) {
            this._initUserLanguage();
        } else {
            this._preferredLanguage = this._locale;
        }
        this.setReady();
    }

    deleteUserPreferedLanguage(): void {
        localStorage.removeItem(LANGUAGE_STORAGE_KEY);
    }

    saveUserPreferedLanguage(lang: string): void {
        localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
    }

    addStrings(lang: string, strings: Dictionary<any>): void {
        this.languages[lang] = [strings];
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;

        if (this._config.isLocalOrTestEnvironment) {
            this.saveUserPreferedLanguage(lang);
        }
    }

    switchCurrentLanguage(lang: string): void {
        if (this._config.isLocalOrTestEnvironment) {
            this._lgLoaderService.show();
            this.setPreferredLanguage(lang);
            window.location.reload();
        }
    }

    private _initUserLanguage(): void {
        if (this.userPreferedLanguage) {
            if (this.availableLanguages.includes(this.userPreferedLanguage)) {
                this._preferredLanguage = this.userPreferedLanguage;
                if (this._config.isLocalOrTestEnvironment && this._preferredLanguage === "zu-ZA") {
                    this._loadCrowdinInContext();
                }
            } else {
                this.deleteUserPreferedLanguage();
            }
        } else {
            this._preferredLanguage = this._locale;
        }
    }

    private _loadCrowdinInContext(): void {
        const url = "jipt-scripts.js";
        loadScript(url, true).then(
            () => {
                // crowdin in-context loaded
            },
            () => {
                this.deleteUserPreferedLanguage();
                window.location.reload();
            }
        );
    }
}
