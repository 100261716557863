import { Component, OnDestroy, inject } from "@angular/core";
import localeNl from "@angular/common/locales/nl";
import localeZu from "@angular/common/locales/zu";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { Auth0AuthorizationService } from "gli-authorization";
import { LgMatTrackingService } from "@logex/framework/lg-application";
import { ApplicationInsightsConfiguration } from "./shared";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [useTranslationNamespace("APP._Root")]
})
export class AppComponent implements OnDestroy {
    private _applicationInsights = inject(ApplicationInsightsConfiguration);
    private _auth0Authorization = inject(Auth0AuthorizationService);
    private _matomoTracking = inject(LgMatTrackingService);

    private readonly _destroyed$ = new Subject<void>();
    _errorMessage: string = null;

    constructor() {
        registerLocaleData(localeNl);
        registerLocaleData(localeEn);
        registerLocaleData(localeZu);

        this._auth0Authorization.authorizationError$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(error => {
                this._errorMessage = error.message;
                console.log(error);
                setTimeout(() => this._logout(), 10000);
            });

        this._auth0Authorization.newProfile$.subscribe(() => window.location.reload());

        this._auth0Authorization.userProfile$.pipe(distinctUntilChanged()).subscribe(user => {
            if (user) {
                this._matomoTracking.setUserID(user.email);
                this._applicationInsights.setUserID(user.email);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _logout($event?: Event): void {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        this._auth0Authorization.logout();
    }
}
