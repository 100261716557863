import { AbstractControl, ValidationErrors } from "@angular/forms";

const PHONE_REGEXP =
    /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;

// TODO: make it possible to lokalize?
export function phoneValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value == null || value.length === 0) {
        return null;
    }
    return PHONE_REGEXP.test(control.value) ? null : { phone: true };
}
