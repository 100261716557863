import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { UiCoreModule } from "@logex/framework/ui-core";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";
import {
    ReferenceTranslateCompiler,
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    useMessageFormatLocales
} from "@logex/framework/lg-localization";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LgConsoleConfiguration, LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";
import { ApplicationInsightsConfiguration, UmLocalizationSettings } from "./shared/services";
import {
    LG_NAVIGATION,
    LG_USER_INFO,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    IAppSession,
    VersionService,
    ScenarioService,
    LgMatTrackingService,
    LG_MATOMO_CONFIGURATION,
    LgApplicationInsightsService,
    LG_APPLICATION_INSIGHTS_CONFIGURATION
} from "@logex/framework/lg-application";
import { navigation } from "./shared/app-navigation";
import { AppStartupService } from "./shared/services/app-startup.service";
import { UM_APP_CONFIG } from "./shared/bases/gateway-base";
import { AdminAppConfiguration } from "./shared/types/um-app-configuration";
import { AppUser } from "./shared/types/app-user";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import {
    LgFiltersetStateGateway,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE,
    LgLayoutModule
} from "@logex/framework/lg-layout";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { GliAuthorizationModule, AUTH0_CONFIG, Auth0InterceptorService } from "gli-authorization";
import { GliApiModule, GLI_API_CONFIGURATION } from "gli-api";
import { ConfigService } from "./shared/services/config.service";
import { AuthorizationGuard } from "./shared/services/authorization.guard";
import { DocusignFinishedComponent } from "./docusign/docusign-finished/docusign-finished.component";
import { DocusignCanceledComponent } from "./docusign/docusign-canceled/docusign-canceled.component";
import { UmAppState } from "./shared/services/um-app-state.service";
import { DocusignComponent } from "./docusign/docusign/docusign.component";
import { MatomoConfiguration } from "./shared/services/matomo-configuration";
import { AccessDeniedComponent } from "./access-denied-page/access-denied.component";

const dummySession: IAppSession = {
    clientId: 10003,
    client: {
        id: 10003,
        code: "123",
        name: "Test"
    },
    scenarioId: 1
};

@NgModule({
    declarations: [
        AppComponent,
        AccessDeniedComponent,
        DocusignFinishedComponent,
        DocusignCanceledComponent,
        DocusignComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UiCoreModule,
        UiToolboxModule,
        LgPivotTableModule,
        // LgApplicationModule.forRoot(),
        LgFiltersetModule.forRoot(),
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        GliAuthorizationModule.forRoot(),
        GliApiModule,
        AppRoutingModule,
        LgLayoutModule
    ],
    providers: [
        LgFiltersetStateGateway,
        AuthorizationGuard,
        UmAppState,
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                return config;
            }
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: UmLocalizationSettings
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB", "zu", "zu-ZA"]),
        {
            provide: LOCALE_ID,
            useValue: "nl-NL"
        },
        {
            provide: VersionService,
            useExisting: ScenarioService
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: LG_NAVIGATION,
            useFactory: () => navigation()
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new AdminAppConfiguration()
        },
        {
            provide: UM_APP_CONFIG,
            useValue: new AdminAppConfiguration()
        },
        {
            provide: LG_APP_SESSION,
            useValue: dummySession
        },
        {
            provide: ConfigService,
            useClass: ConfigService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (startup: ConfigService) =>
                startup.configurationPromise.then(config => config.auth0),
            deps: [ConfigService]
        },
        {
            provide: GLI_API_CONFIGURATION,
            useFactory: (startup: ConfigService) =>
                startup.configurationPromise.then(config => config.api),
            deps: [ConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useClass: MatomoConfiguration,
            deps: [ConfigService]
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgApplicationInsightsService,
            multi: true
        },
        {
            provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
            useClass: ApplicationInsightsConfiguration,
            deps: [ConfigService]
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
