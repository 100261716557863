import { Component, Input, Output, EventEmitter } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

@Component({
    selector: "app-agb-input",
    templateUrl: "./agb-input.component.html",
    viewProviders: [useTranslationNamespace("APP._Components._AgbInput")]
})
export class AgbInputComponent {
    @Input() valid = false;

    @Input() code: string;
    @Output() readonly codeChange = new EventEmitter<string>(true);

    @Input() error: string;
    @Input() disabled = false;
    @Input() working = false;
    @Input() locked = false;
    @Input() defaultFocus = false;

    @Output() readonly checkCode = new EventEmitter<string>(true);
    @Output() readonly resetCode = new EventEmitter<void>(true);
    @Output() readonly resetError = new EventEmitter<void>(true);

    readonly _agbCodePattern = /^[0-9]{6,8}$/;
    _checked = false;

    _checkCode(invalid: boolean): void {
        this._checked = true;
        if (invalid) return;

        this._appendInitialZeros();

        this.checkCode.next(this.code);
    }

    _resetCode(): void {
        this._checked = false;
        this.resetCode.next();
        Promise.resolve().then(() => {
            this.defaultFocus = true;
        });
    }

    private _appendInitialZeros(): void {
        if (this.code.length < 8) {
            this.code = `0000000${this.code}`.slice(-8);
            this.codeChange.emit(this.code);
        }
    }
}
