import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl
} from "@logex/framework/lg-application";
import { ConfigService } from "./config.service";

export class MatomoConfiguration implements IMatomoConfiguration {
    siteId: number;
    statUrl: string;
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions: IMatomoDimension[];
    registry?: string;
    autoInit: boolean;
    autoTrack: boolean;

    constructor(private _configService: ConfigService) {
        this.siteId = this._getSiteId();
        this.commonDimensionIds = this._getCommonDimensionIds();
        this.statUrl = MatomoStatsUrl.MRDM;
        this.registry = "gli";
        this.customDimensions = null;
        this.autoInit = true;
        this.autoTrack = true;
    }

    doNotDoMatTracking(): boolean {
        return !this._configService.isLocalOrTestEnvironment && !this._configService.isProd;
    }

    private _getSiteId(): number {
        if (this._configService.isLocalOrTestEnvironment) {
            return 32;
        }
        if (this._configService.isProd) {
            return 33;
        }
        return null;
    }

    private _getCommonDimensionIds(): IMatomoCommonDimensionIds {
        const commonDimensions = {} as IMatomoCommonDimensionIds;

        if (this._configService.isLocalOrTestEnvironment) {
            commonDimensions.registry = 1;
        }
        if (this._configService.isProd) {
            commonDimensions.registry = 1;
        }

        return commonDimensions;
    }
}
