export enum AppUserGender {
    Male = 1,
    Female = 2,
    Unknown = 0
}

export enum AppUserCpStatus {
    No = 0,
    Yes = 1,
    Removed = 2
}

export interface AppUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    gender: AppUserGender;
    position: string | null;
    phone: string;
    created: Date;
    blocked: boolean;
    cpStatus: AppUserCpStatus;
    comment: string | null;
}
