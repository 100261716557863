import { IUser } from "@logex/framework/lg-application";

export class AppUser implements IUser {
    id: number;
    name: string;
    roles: _.Dictionary<boolean>;
    title: string;
    userid: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;

    constructor() {
        this.roles = {};
    }

    public hasPermission(role: string): boolean {
        return this.roles[role] || false;
    }
}
