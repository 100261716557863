import { Auth0Config } from "gli-authorization";
import { GliApiConfiguration } from "gli-api";

export interface AppConfiguration {
    instance: string;
    environment: string;
    auth0: Auth0Config;
    api: GliApiConfiguration;
    managementUrl: string;
    heplUrl: string;
    signUpUrl: string;
    surveyUrl: string;
    applicationInsights: {
        instrumentationKey: string | null;
        correlationHeaderExcludedDomains: string[];
    };
}
