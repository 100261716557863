import { PivotOrganization } from "../../authorized/organizations/pivot/organization.pivot.types";

export class UserOrganizationStatus {
    organization: PivotOrganization;
    linkStatus: number;
    userId: number;
    userEmrConnections: number[];
    statusChanging?: boolean;
    eventSent?: boolean;
    eventSending?: boolean;
}
