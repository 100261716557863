import { Injectable, inject } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    ServiceProviderStatus,
    ProfessionalLinkLinkStatus,
    GliConfigurationService,
    GliOrganisationContactsService
} from "gli-api";
import { Dictionary } from "lodash";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { EnumTranslatePipe } from "../pipes";
import { AdminAppEmrProvider, AdminAppEmrProviderStatus } from "../types/emr-provider";

const SERVICE_PROVIDER_STATUS_ORDER_BY_MAPPING = {
    0: 0, // Created
    1: 1, // Emailed
    2: 2, // Signed
    3: 3, // Approved
    4: 4, // Refused
    5: 5 // Aborted
};

const HP_STATUS_ORDER_BY_MAPPING = {
    0: 0, // Pending
    1: 1, // Approved
    2: 2, // Denied
    3: 3, // Disabled
    4: 4 // Removed
};

@Injectable()
export class DefinitionsService {
    private _configurationService = inject(GliConfigurationService);
    private _contactsService = inject(GliOrganisationContactsService);
    private _enumTranslate = inject(EnumTranslatePipe);
    private _lgTranslate = inject(LgTranslateService);

    _contactTypes;
    _EMRProviders: AdminAppEmrProvider[];
    _EMRProvidersLookup: Dictionary<AdminAppEmrProvider>;

    // getDisplayName(id, code) {
    //     switch (id) {
    //         case "application":
    //             const name = this._applications.find(app => app.id === code)
    //                 ? this._applications.find(app => app.id === code).name
    //                 : (code as string);
    //             return name;
    //         default:
    //             return "no name";
    //     }
    // }

    async fillDefinitions(): Promise<void> {
        await forkJoin([
            this._configurationService.getEmrProviders(),
            this._contactsService.getContactTypes()
        ])
            .pipe(
                tap(([emrProviders, contactTypes]) => {
                    this._EMRProviders = emrProviders.map(x => {
                        let status = AdminAppEmrProviderStatus.Ok;
                        let icon = {
                            icon: "",
                            styledTitle: ""
                        };

                        if (!x.isDefault) {
                            if (!x.planned) {
                                status = AdminAppEmrProviderStatus.NotSupported;
                                icon = {
                                    icon: "icon-help-gli-emr",
                                    styledTitle: this._enumTranslate.transform(
                                        status,
                                        "AdminAppEmrProviderStatus"
                                    )
                                };
                            } else if (!x.implemented) {
                                status = AdminAppEmrProviderStatus.WaitingForImplementation;
                                icon = {
                                    icon: "icon-time",
                                    styledTitle: this._enumTranslate.transform(
                                        status,
                                        "AdminAppEmrProviderStatus"
                                    )
                                };
                            }
                            return { ...x, icon, status };
                        } else {
                            return {
                                ...x,
                                icon,
                                status: AdminAppEmrProviderStatus.Survey,
                                name: this._lgTranslate.translate("APP._Definitions.SurveyProvider")
                            };
                        }
                    });
                    this._EMRProvidersLookup = {};
                    this._EMRProviders.forEach(emrProvider => {
                        this._EMRProvidersLookup[emrProvider.id] = emrProvider;
                    });
                    this._contactTypes = contactTypes;
                })
            )
            .toPromise();
    }

    public getEmrProvider(id: number): AdminAppEmrProvider | undefined {
        return this._EMRProvidersLookup[id];
    }

    public getServiceProviderStatusOrderBy(status: ServiceProviderStatus): number {
        return SERVICE_PROVIDER_STATUS_ORDER_BY_MAPPING[status];
    }

    public getHPStatusOrderBy(status: ProfessionalLinkLinkStatus): number {
        return HP_STATUS_ORDER_BY_MAPPING[status];
    }
}
