import { IsServiceProviderAllowedResponse } from "./is-service-provider-allowed-response";

export type LinkServiceProviderResponse =
    | IsServiceProviderAllowedResponse
    | {
          response: "invalid-organisation-code";
      }
    | {
          response: "ok";
          autoConfirmed: boolean;
      };
