import { ServiceProviderContactPersonFull } from "./service-provider-contact-person";

export type InviteContactPersonResponse =
    | {
          result: "ok";
          contactPerson: ServiceProviderContactPersonFull;
      }
    | {
          result: "already-invited" | "already-member" | "not-registered";
      };
