import { Component, inject } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { Auth0AuthorizationService, Auth0User } from "gli-authorization";

@Component({
    selector: "access-denied",
    templateUrl: "./access-denied.component.html",
    providers: [useTranslationNamespace("APP._AccessDenied")],
    host: {
        class: "flex-flexible flexcol flexcol--full"
    }
})
export class AccessDeniedComponent {
    private _auth0Authorization = inject(Auth0AuthorizationService);

    _logout(): void {
        this._auth0Authorization.logout();
    }

    get _getUser(): Auth0User {
        return this._auth0Authorization.currentUserProfile;
    }
}
