import { AbstractControl, ValidationErrors } from "@angular/forms";

export const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function emailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value == null || value.length === 0) {
        return null;
    }
    return EMAIL_REGEXP.test(control.value) ? null : { email: true };
}
