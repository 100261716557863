import { Component, Input } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { TooltipPosition } from "@logex/framework/ui-core";

@Component({
    selector: "lg-simple-copy-button",
    templateUrl: "./lg-simple-copy-button.component.html",
    providers: [useTranslationNamespace("APP._Dialogs.InviteUserDialog")]
})
export class LgSimpleCopyButtonComponent {
    @Input() elemToCopy: Node;
    @Input() asIcon: boolean;
    @Input() iconClass: string;
    @Input() notificationPosition: TooltipPosition;
    @Input() set buttonClass(value: string) {
        this._buttonClass = value;
    }

    get buttonClass(): string {
        return this._buttonClass;
    }

    private _buttonClass: string;

    _copyData(): void {
        if (this.elemToCopy.nodeName === "TEXTAREA") {
            (this.elemToCopy as HTMLTextAreaElement).select();
            document.execCommand("copy");
            const selection = window.getSelection();
            selection.removeAllRanges();
        } else {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(this.elemToCopy);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
            selection.removeAllRanges();
        }
    }
}
