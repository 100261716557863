export enum ServiceProviderStatus {
    Created = 0,
    Emailed = 1,
    Signed = 2,
    Approved = 3,
    Refused = 4,
    Aborted = 5,
    AwaitingCpConfirmation = 6,
    RefusedCpConfirmation = 7
}

export interface ServiceProvider {
    id: number;
    agbCode: string;
    name: string;
    address: string;
    city: string;
    zip: string;
    manualEntry: boolean;
    manualNameEntry: boolean;
    manualAddressEntry: boolean;
    specialConditions: boolean;
    selfEmployed: boolean;
    token: string;
    status: ServiceProviderStatus;
    originalName: string;
    confirmationEmail: string;
    confirmationToken: string;
    created: Date;
    tokenCreated: Date;
}
