import * as _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, switchMap, mergeMap } from "rxjs/operators";
import { GLI_API_CONFIGURATION } from "./gli-api-configuration";
import { AppUser, OrganisationContactType, OrganisationContact } from "./models";
import { GatewayBase, JsonDates } from "./gateway-base";

@Injectable({ providedIn: "root" })
export class GliOrganisationContactsService extends GatewayBase {
    private _configuration = inject(GLI_API_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    getContactTypes(): Observable<OrganisationContactType[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<OrganisationContactType[]>(
                    `${url}/api/cp/service-provider/contacts/types`
                )
            )
        );
    }

    getOrganisationContacts(serviceProviderId: number): Observable<OrganisationContact[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<OrganisationContact[]>
                    >(`${url}/api/cp/service-provider/${serviceProviderId}/contacts`)
                    .pipe(map(ca => _.map(ca, c => this._remapOrganisationContact(c))))
            )
        );
    }

    getAllOrganisationContacts(): Observable<OrganisationContact[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<OrganisationContact[]>
                    >(`${url}/api/cp/service-provider/contacts`)
                    .pipe(map(ca => _.map(ca, c => this._remapOrganisationContact(c))))
            )
        );
    }

    insertContact(
        contact: Omit<OrganisationContact, "id" | "updated" | "updatedBy">
    ): Observable<number> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<number>(
                    `${url}/api/cp/service-provider/${contact.serviceProviderId}/contacts`,
                    contact
                )
            )
        );
    }

    updateContact(contact: Omit<OrganisationContact, "updated" | "updatedBy">): Observable<void> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.patch<void>(
                    `${url}/api/cp/service-provider/${contact.serviceProviderId}/contacts/${contact.id}`,
                    contact
                )
            )
        );
    }

    deleteContact(serviceProviderId: number, id: number): Observable<void> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.delete<void>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/contacts/${id}`
                )
            )
        );
    }

    private _remapAppUser(user: JsonDates<AppUser>): AppUser {
        if (user == null) return null;
        return {
            ...user,
            created: this._parseDateTime(user.created)
        };
    }

    private _remapOrganisationContact(
        contact: JsonDates<OrganisationContact>
    ): OrganisationContact {
        return {
            ...contact,
            updated: this._parseDateTime(contact.updated)
        };
    }

    private _getUrl(): Observable<string> {
        return from(this._configuration).pipe(map(config => config.url));
    }
}
