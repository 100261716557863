import { Injectable } from "@angular/core";
import { AppConfiguration } from ".";

@Injectable()
export class ConfigService {
    configuration: AppConfiguration;
    configurationPromise: Promise<AppConfiguration>;
    private _resolve: (value: AppConfiguration) => void;

    constructor() {
        this.configurationPromise = new Promise<AppConfiguration>(resolve => {
            this._resolve = resolve;
        });
    }

    _setConfigation(val: AppConfiguration): void {
        this.configuration = val;
        val.api.url = val.api.url.replace(/\/$/, "");
        this._resolve(val);
        this._resolve = null;
    }

    get isLocalOrTestEnvironment(): boolean {
        if (this.configuration) {
            return (
                this.configuration.environment === "local" ||
                this.configuration.environment === "test"
            );
        }
        return false;
    }

    get isTest(): boolean {
        return this.configuration && this.configuration.environment === "test";
    }

    get isProd(): boolean {
        return this.configuration && this.configuration.environment === "prod";
    }
}
