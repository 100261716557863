import { Component } from "@angular/core";

@Component({
    selector: "um-sidebar-settings",
    templateUrl: "./um-sidebar-settings.component.html",
    host: {
        class: "lg-sidebar-settings lg-sidebar-panel"
    }
})
export class UmSidebarSettingsComponent {}
