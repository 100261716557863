import { EmrProvider } from "gli-api";

export enum AdminAppEmrProviderStatus {
    Ok = 0,
    WaitingForImplementation = 1,
    NotSupported = 2,
    Survey = 3
}

export interface AdminAppEmrProvider extends EmrProvider {
    status: AdminAppEmrProviderStatus;
    icon: {
        icon: string;
        styledTitle: string;
    };
}
