import { INavNode } from "@logex/framework/lg-application";

export const navigation: () => INavNode[] = () =>
    [
        {
            path: "",
            id: "root",
            noBreadcrumb: true,

            children: [
                {
                    id: "organizations",
                    path: "management/organizations",
                    lid: "APP._Navigation.Organizations",
                    anonymous: false
                }
            ]
        }
    ] as INavNode[];
