import { ExportBase } from "../bases/export-base";
import { Injectable, inject } from "@angular/core";
import { LogexPivotService, INormalizedLogexPivotDefinition } from "@logex/framework/lg-pivot";
import {
    LgExcelFactoryService,
    IMultiPartDefinition,
    IColumnExportDefinition
} from "@logex/framework/lg-exports";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgMonthNamePipe } from "@logex/framework/ui-core";

@Injectable({ providedIn: "root" })
export class ExportService extends ExportBase {
    _translationNamespace = "APP._OrganizationOverview.Headers";

    constructor() {
        const logexPivot = inject(LogexPivotService);
        const lgXlsxService = inject(LgExcelFactoryService);
        const lgTranslate = inject(LgTranslateService);
        const monthNamePipe = inject(LgMonthNamePipe);

        super(logexPivot, lgXlsxService, lgTranslate, monthNamePipe);

        this.lgXlsx = lgXlsxService.create();
        this.lgXlsx.setDefaultStyle(this.lgXlsx.styles.logex);
    }

    exportVisible(
        organizationPivotDefinition: INormalizedLogexPivotDefinition,
        filteredData: any[]
    ): void {
        const allDefinitions: IMultiPartDefinition[] = [];

        allDefinitions.push({
            bigHeader: this.translate(".ExportOverview"),
            type: "pivot",
            pivotDefinition: organizationPivotDefinition,
            data: filteredData,
            itemNames: ["organization", "user"],
            definition: <IColumnExportDefinition[]>[
                {
                    name: this.translate(".OrganizationId"),
                    content: "organization.id",
                    hAlign: "left"
                },
                {
                    name: this.translate(".OrganizationName"),
                    content: "organization.name",
                    hAlign: "left"
                },
                {
                    name: this.translate(".UserName"),
                    content: "user.name",
                    width: 30
                },
                {
                    name: this.translate(".Email"),
                    content: "user.email",
                    width: 30
                },
                {
                    name: this.translate(".LastLogin"),
                    content: "user.lastLogin",
                    hAlign: "left"
                },
                {
                    name: this.translate(".Created"),
                    content: "user.createdAt",
                    hAlign: "left"
                }
            ]
        });
        this.lgXlsx.saveMultiple(
            {
                filename: this.translateFileNameWithCurrentTime(
                    "APP._OrganizationOverview.UsersExport"
                )
            },
            allDefinitions
        );
    }
}
