export type JsonDates<T> = T extends Date
    ? string
    : T extends number
    ? number
    : T extends string
    ? T
    : T extends boolean
    ? boolean
    : T extends Array<infer A>
    ? Array<JsonDates<A>>
    : {
          [K in keyof T]: JsonDates<T[K]>;
      };

export class GatewayBase {
    protected _parseDate(date: string | null): Date | null {
        if (date == null) return null;
        const parts = date.substring(0, 10).split("-");
        return new Date(+parts[0], +parts[1] - 1, +parts[2]);
    }

    protected _parseDateTime(date: string | null): Date | null {
        if (date == null) return null;
        return new Date(date);
    }
}
