import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { Router, ActivatedRoute } from "@angular/router";
import { GliQueryService, DocusignContractStatus } from "gli-api";
import { PageComponentBase } from "../../shared/bases/page-component-base";
import { takeUntil, take, finalize } from "rxjs/operators";
import { Subject, forkJoin, timer } from "rxjs";
import { ConfigService } from "../../shared/services/config.service";

const MINIMAL_TIME_ON_PAGE = 15000;
const WAITING_TIME_BEFORE_REDIRECT = 5000;
const MAX_STATUS_REQUESTS = 24;
const NEXT_STATUS_REUEST_DELAY = 5000;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "app-docusign-canceled",
    templateUrl: "./docusign-finished.component.html",
    providers: [useTranslationNamespace("APP._Docusign.Finished")]
})
export class DocusignFinishedComponent extends PageComponentBase implements OnInit, AfterViewInit {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _config = inject(ConfigService);
    private _gliQueryService = inject(GliQueryService);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);

    public _contractStatus: DocusignContractStatus;
    public _redirectUrl: string;
    public _contractStatusError: boolean;
    public _maxRquestsreached: boolean;

    private _minimalTimeSpentOnPage$: Subject<boolean>;
    private _redirect$: Subject<void>;

    private _envelopeId: string;
    private _recipientEmail: string;
    private _contractStatusRequestCount: number;

    constructor() {
        super();
        this._minimalTimeSpentOnPage$ = new Subject();
        this._redirect$ = new Subject();
        this._contractStatus = "pending";
        this._contractStatusRequestCount = 0;
    }

    ngOnInit(): void {
        forkJoin(this._minimalTimeSpentOnPage$, this._redirect$).subscribe(() => this._redirect());

        this._envelopeId = this._route.snapshot.paramMap.get("id");
        this._recipientEmail = this._route.snapshot.queryParamMap.get("email");
        this._checkStatus();
    }

    ngAfterViewInit(): void {
        timer(MINIMAL_TIME_ON_PAGE)
            .pipe(take(1))
            .subscribe(() => {
                this._minimalTimeSpentOnPage$.next(true);
                this._minimalTimeSpentOnPage$.complete();
            });
    }

    public _redirect(): void {
        if (this._redirectUrl) {
            window.location.replace(this._redirectUrl);
        }
    }

    private _checkStatus(): void {
        this._contractStatusRequestCount++;
        this._gliQueryService
            .getDocusignContractStatus(this._envelopeId, this._recipientEmail)
            .pipe(
                take(1),
                takeUntil(this._destroyed$),
                finalize(() => this._changeDetectorRef.detectChanges())
            )
            .subscribe(
                (res: DocusignContractStatus) => {
                    this._contractStatus = res;
                    switch (res) {
                        case "done":
                        case "refused":
                            this._redirectUrl = this._config.configuration.managementUrl;
                            timer(WAITING_TIME_BEFORE_REDIRECT)
                                .pipe(take(1))
                                .subscribe(() => {
                                    this._redirect$.next();
                                    this._redirect$.complete();
                                });
                            break;
                        case "done-self-employed":
                            this._redirectUrl = this._config.configuration.surveyUrl;
                            timer(WAITING_TIME_BEFORE_REDIRECT)
                                .pipe(take(1))
                                .subscribe(() => {
                                    this._redirect$.next();
                                    this._redirect$.complete();
                                });
                            break;
                        case "done-self-employed-emr":
                            this._redirectUrl = `${this._config.configuration.managementUrl}/organizations/all?selfEmployedEmrRedirect=true`;
                            timer(WAITING_TIME_BEFORE_REDIRECT)
                                .pipe(take(1))
                                .subscribe(() => {
                                    this._redirect$.next();
                                    this._redirect$.complete();
                                });
                            break;
                        case "done-self-employed-pending-emr":
                            this._redirect$.complete();
                            break;
                        case "pending":
                            if (this._contractStatusRequestCount < MAX_STATUS_REQUESTS) {
                                setTimeout(() => this._checkStatus(), NEXT_STATUS_REUEST_DELAY);
                            } else {
                                this._maxRquestsreached = true;
                            }
                            break;
                    }
                },
                () => (this._contractStatusError = true)
            );
    }
}
