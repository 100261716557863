export interface EmrProvider {
    id: number;
    name: string;
    vendor: string;
    shortName: string | null;
    implemented: boolean;
    planned: boolean;
    isDefault: boolean;
    isDisabled: boolean;
}
