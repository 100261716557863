import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { GLI_API_CONFIGURATION } from "./gli-api-configuration";
import {
    EmailVerifiedResponse,
    SendVerificationEmailResult,
    VerifyEmailResult,
    RegisterAsCpArgs,
    RegisterAsCpResult,
    CpStatusResult,
    RegisterAsStandaloneArgs,
    RegisterAsStandaloneResult,
    GetHpTokenResponse,
    GetHpTokenStatusResponse,
    RegisterAsHpArgs,
    RegisterAsHpResponse,
    GetCpConfirmationStatusResponse
} from "./gli-user-types";

@Injectable({ providedIn: "root" })
export class GliUserService {
    private _configuration = inject(GLI_API_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    getEmailVerified(email: string): Observable<EmailVerifiedResponse> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<EmailVerifiedResponse>(`${url}/api/user/verify`, {
                    params: { email }
                })
            )
        );
    }

    sendVerificationEmail(
        email: string,
        invitationToken?: string
    ): Observable<SendVerificationEmailResult> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<SendVerificationEmailResult>(`${url}/api/user/verify`, null, {
                    params: {
                        invitationToken,
                        email
                    }
                })
            )
        );
    }

    verifyEmail(token: string): Observable<VerifyEmailResult> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<VerifyEmailResult>(
                    `${url}/api/user/verify/token/${token}`,
                    null
                )
            )
        );
    }

    getCpStatus(): Observable<CpStatusResult> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<CpStatusResult>(`${url}/api/contact-person/status`)
            )
        );
    }

    registerAsCp(args: RegisterAsCpArgs): Observable<RegisterAsCpResult> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<RegisterAsCpResult>(
                    `${url}/api/contact-person/register`,
                    args
                )
            )
        );
    }

    registerAsStandalone(args: RegisterAsStandaloneArgs): Observable<RegisterAsStandaloneResult> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<RegisterAsStandaloneResult>(
                    `${url}/api/contact-person/standalone`,
                    {
                        ...args,
                        emrProviderId: undefined,
                        emrProviderIds: [args.emrProviderId]
                    }
                )
            )
        );
    }

    registerAsHp(token: string, args: RegisterAsHpArgs): Observable<RegisterAsHpResponse> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<RegisterAsHpResponse>(
                    `${url}/api/professional/register/${token}`,
                    args
                )
            )
        );
    }

    getHpToken(token: string): Observable<GetHpTokenResponse> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<GetHpTokenResponse>(`${url}/api/professional/token/${token}`)
            )
        );
    }

    getHpTokenStatus(token: string, email: string): Observable<GetHpTokenStatusResponse> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<GetHpTokenStatusResponse>(
                    `${url}/api/professional/token/${token}/status`,
                    {
                        params: { email }
                    }
                )
            )
        );
    }

    getHpAgbStatusForToken(token: string, personalAgbCode: string): Observable<boolean> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<boolean>(
                    `${url}/api/professional/token/${token}/agb/${personalAgbCode}`
                )
            )
        );
    }

    getCpConfirmationStatus(token: string): Observable<GetCpConfirmationStatusResponse> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<GetCpConfirmationStatusResponse>(
                    `${url}/api/contact-person/confirmation/${token}`
                )
            )
        );
    }

    setCpConfirmationStatus(token: string, approve: boolean): Observable<boolean> {
        return this._getUrl().pipe(
            mergeMap(url =>
                this._httpClient.post<boolean>(`${url}/api/contact-person/confirmation`, {
                    token,
                    approve
                })
            )
        );
    }

    private _getUrl(): Observable<string> {
        return from(this._configuration).pipe(map(config => config.url));
    }
}
