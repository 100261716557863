export type ServiceProviderRelationsStatus =
    // code not found
    | "invalid-organisation-code"
    // the code belongs to mother organisation
    | "branch-both"
    | "branch-yours"
    | "branch-others"
    // the code belongs to a branch
    | "organisation-yours"
    | "organisation-others"
    // either level, no relation registered
    | "free";
