import { ServiceProviderEmrConnectionStatus } from "./service-provider-emr-connection-status";
import { ServiceProvider } from "./service-provider";
import { EmrProvider } from "./emr-provider";

export interface ServiceProviderEmrConnection {
    id: number;
    serviceProviderId: number;
    emrProviderId: number;
    name: string;
    configuration: any;
    status: ServiceProviderEmrConnectionStatus;
}

export interface ServiceProviderEmrConnectionWithOrganization extends ServiceProviderEmrConnection {
    serviceProvider: ServiceProvider;
}

export interface ServiceProviderEmrConnectionFull
    extends ServiceProviderEmrConnectionWithOrganization {
    emrProvider: EmrProvider;
}
