export interface UserArguments {
    name?: string;
    username?: string;
    email?: string;
    app_metadata?: AppMetadata;
}

export interface AppMetadata {
    applications?: string[];
    healthcareserviceproviders?: string[];
}
