export interface AuthUser {
    "https://mrdm.io/applications": string[];
    "https://mrdm.io/healthcareserviceproviders": string[];

    name: string;
    given_name: string;
    family_name: string;
    nickname: string;
    email: string;
    email_verified: boolean;
    picture: string;
    updated_at: string;
    sub: string;
}
