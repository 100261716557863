import { JsonDates } from "./gateway-base";
import {
    AppUser,
    Professional,
    ProfessionalLinkFull,
    ServiceProvider,
    ServiceProviderContactPersonFull
} from "./models";

export function parseJsonDateTime(date: string | null): Date | null {
    if (date == null) return null;
    return new Date(date);
}

export function remapAppUser(user: JsonDates<AppUser>): AppUser {
    if (user == null) return null;
    return {
        ...user,
        created: parseJsonDateTime(user.created)
    };
}

export function remapServiceProvider(provider: JsonDates<ServiceProvider>): ServiceProvider {
    if (provider == null) return null;
    return {
        ...provider,
        created: parseJsonDateTime(provider.created),
        tokenCreated: parseJsonDateTime(provider.tokenCreated)
    };
}

export function remapServiceProviderContactPersonFull(
    person: JsonDates<ServiceProviderContactPersonFull>
): ServiceProviderContactPersonFull {
    if (person == null) return null;
    return {
        ...person,
        user: remapAppUser(person.user),
        serviceProvider: remapServiceProvider(person.serviceProvider),
        created: parseJsonDateTime(person.created),
        statusTimestamp: parseJsonDateTime(person.statusTimestamp)
    };
}

export function remapProfessionalLinkFull(
    link: JsonDates<ProfessionalLinkFull>
): ProfessionalLinkFull {
    if (link == null) return null;
    return {
        ...link,
        created: parseJsonDateTime(link.created),
        userStatusTimestamp: parseJsonDateTime(link.userStatusTimestamp),
        linkStatusTimestamp: parseJsonDateTime(link.linkStatusTimestamp),
        importStatusTimestamp: parseJsonDateTime(link.importStatusTimestamp),
        professional: remapProfessional(link.professional),
        serviceProvider: remapServiceProvider(link.serviceProvider)
    };
}

export function remapProfessional(professional: JsonDates<Professional>): Professional {
    return {
        ...professional,
        created: parseJsonDateTime(professional.created),
        user: remapAppUser(professional.user)
    };
}
