export interface OrganisationContact {
    id: number;
    serviceProviderId: number;
    contactTypeId: number;
    contactType?: string;
    name: string;
    email: string | null;
    phone: string | null;
    updated: Date;
    updatedBy: number;
}
