import { Injectable, inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Route,
    UrlSegment,
    Router
} from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError, switchMap, take } from "rxjs/operators";
import { Auth0AuthorizationService } from "gli-authorization";
import { UmAppState } from "./um-app-state.service";

@Injectable({
    providedIn: "root"
})
export class AuthorizationGuard {
    private _auth = inject(Auth0AuthorizationService);
    private _router = inject(Router);
    private _umAppState = inject(UmAppState);

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this._checkAccess();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._checkAccess();
    }

    private _checkAccess(): Observable<boolean> {
        return this._auth.isAuthenticated$.pipe(
            take(1),
            switchMap(loggedIn => {
                if (loggedIn) {
                    return this._umAppState.currentCP.pipe(
                        catchError(() => {
                            return of({ blocked: true, cpStatus: null });
                        }),
                        map(res => {
                            if (res.cpStatus !== 1 || res.blocked === true) {
                                this._router.navigate(["access-denied"]);
                                return false;
                            }
                            return true;
                        })
                    );
                } else {
                    return of(false);
                }
            })
        );
    }
}
