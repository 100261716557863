import { Professional } from "./professional";
import { ServiceProvider } from "./service-provider";

export enum ProfessionalLinkUserStatus {
    Created = 0,
    Signed = 1
}

export enum ProfessionalLinkLinkStatus {
    Pending = 0,
    Approved = 1,
    Denied = 2,
    Disabled = 3,
    Removed = 4
}

export enum ProfessionalLinkImportStatus {
    NotRequired = 0,
    Requested = 1,
    Processing = 2,
    Imported = 3
}

export interface ProfessionalLink {
    userId: number;
    serviceProviderId: number;
    userStatus: ProfessionalLinkUserStatus;
    linkStatus: ProfessionalLinkLinkStatus;
    importStatus: ProfessionalLinkImportStatus;
    created: Date;
    userStatusTimestamp: Date;
    linkStatusTimestamp: Date;
    importStatusTimestamp: Date;
    emrConnections: number[];
}

export interface ProfessionalLinkFull extends ProfessionalLink {
    professional: Professional;
    serviceProvider: ServiceProvider;
}
