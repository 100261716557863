import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/admin-app/src/environments/environment";
import { tap } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { AppConfiguration } from "./app-configuration";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";
import { UmLocalizationSettings } from "./um-localization-settings.service";

@Injectable({ providedIn: "root" })
export class AppStartupService {
    private _configService = inject(ConfigService);
    private _http = inject(HttpClient);
    private _localizationSettings = inject<UmLocalizationSettings>(LG_LOCALIZATION_SETTINGS);

    initialize(): () => Promise<void> {
        return async () => {
            await this._loadConfig();
        };
    }

    private async _loadConfig(): Promise<void> {
        this._http
            .get<AppConfiguration>(environment.config)
            .pipe(
                tap(c => {
                    this._configService._setConfigation(c);
                    this._localizationSettings.init();
                })
            )
            .toPromise();
    }

    // private fillAppConfig(config: any) {
    //     this._appConfig.instance = config.instance;
    //     this._appConfig.environment = config.environment;
    //     this._appConfig.applicationRoot = config.api.url;
    //     this._authConfig.domain = config.auth0.domain;
    //     this._authConfig.clientId = config.auth0.clientId;
    //     this._authConfig.audience = config.auth0.audience;
    //     this._authConfig.scope = config.auth0.__scope;
    //     // this._apiConfig.url = config.api.url;
    // }

    // private async _loadConfig(): Promise<void> {
    //     this._http
    //         .get<AppConfiguration>(environment.config)
    //         .pipe(tap(c => this._configService._setConfigation(c)))
    //         .toPromise();
    // }
}
