import { InjectionToken } from "@angular/core";
import { ServerGatewayBase } from "@logex/framework/lg-application";

export interface UmAppConfiguration {
    instance: string;
    environment: string;
    applicationRoot: string;
}

export const UM_APP_CONFIG = new InjectionToken<UmAppConfiguration>("umAppConfiguration");

export class GatewayBase extends ServerGatewayBase {
    constructor(_appConfig: UmAppConfiguration) {
        super();
        this.setBaseUrl(_appConfig.applicationRoot);
    }
}
