import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function uniqueNameValidator(compareValues: string[]): ValidatorFn {
    const transformedValues = compareValues.map(x => ({ name: x, lowerCase: null }));

    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && compareValues.length) {
            let exists = false;
            exists = transformedValues.some(x => {
                if (!x.lowerCase) {
                    x.lowerCase = x.name.toString().toLowerCase();
                }
                return x.lowerCase === control.value.toString().toLowerCase();
            });
            return exists ? { unique: "not unique" } : null;
        }
        return null;
    };
}
