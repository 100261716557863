import { AppMetadata } from "./user-arguments";

export interface UserQueryResult {
    items: Auth0User[];
    length: number;
    limit: number;
    start: number;
    total: number;
}

export interface Auth0User {
    app_metadata: AppMetadata;
    created_at: string;
    email: string;
    last_login: string;
    logins_count: number;
    name: string;
    picture: string;
    user_id: string;
}
