import { AppUser } from "./app-user";

export interface Professional {
    userId: number;
    agbCode: string;
    initials: string;
    insertion: string;
    lastName: string;
    manualEntry: boolean;
    created: Date;
    user: AppUser;
}
