import * as _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, switchMap, concatMap } from "rxjs/operators";
import { GLI_API_CONFIGURATION } from "./gli-api-configuration";
import {
    AppUser,
    ServiceProviderContactPersonFull,
    ProfessionalLinkFull,
    IsServiceProviderAllowedResponse,
    ServiceProviderRelationsStatus,
    InviteProfessionalsResponse,
    SetProfessionalStatusResponseEntry,
    ProfessionalLinkLinkStatus,
    InviteContactPersonResponse,
    ContactPersonInvitationResult,
    SelfProfessional,
    AddOwnProfessionalLinkResult,
    ServiceProviderEmrConnection,
    ServiceProviderEmrConnectionFull,
    UpdateServiceProviderEmrConnectionResult,
    AddProfessionalEmrConnectionResult,
    LinkServiceProviderResponse,
    AddServiceProviderEmrConnectionResponse
} from "./models";
import { GatewayBase, JsonDates } from "./gateway-base";
import * as Mapper from "./gli-json-mapper";

@Injectable({ providedIn: "root" })
export class GliCpService extends GatewayBase {
    private _configuration = inject(GLI_API_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    getSelf(): Observable<AppUser> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<JsonDates<AppUser>>(`${url}/api/cp/contact-person/self`)
                    .pipe(map(res => Mapper.remapAppUser(res)))
            )
        );
    }

    getSelfProfessional(): Observable<SelfProfessional | null> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<SelfProfessional | null>(
                    `${url}/api/cp/contact-person/self/professional`
                )
            )
        );
    }

    getContactPersons(serviceProviderId: number): Observable<ServiceProviderContactPersonFull[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<ServiceProviderContactPersonFull[]>
                    >(`${url}/api/cp/service-provider/${serviceProviderId}/contact-person`)
                    .pipe(
                        map(res =>
                            _.map(res, cp => Mapper.remapServiceProviderContactPersonFull(cp))
                        )
                    )
            )
        );
    }

    inviteContactPerson(
        serviceProviderId: number,
        email: string
    ): Observable<InviteContactPersonResponse> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient
                    .post<
                        JsonDates<InviteContactPersonResponse>
                    >(`${url}/api/cp/service-provider/${serviceProviderId}/contact-person`, { email })
                    .pipe(
                        map(res =>
                            res.result === "ok"
                                ? {
                                      ...res,
                                      contactPerson: Mapper.remapServiceProviderContactPersonFull(
                                          res.contactPerson
                                      )
                                  }
                                : res
                        )
                    )
            )
        );
    }

    acceptContactPersonInvitation(
        serviceProviderId: number
    ): Observable<ContactPersonInvitationResult> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<ContactPersonInvitationResult>(
                    `${url}/api/cp/contact-person/accept/${serviceProviderId}`,
                    null
                )
            )
        );
    }

    refuseContactPersonInvitation(
        serviceProviderId: number
    ): Observable<ContactPersonInvitationResult> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<ContactPersonInvitationResult>(
                    `${url}/api/cp/contact-person/refuse/${serviceProviderId}`,
                    null
                )
            )
        );
    }

    getProfessionals(): Observable<ProfessionalLinkFull[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<JsonDates<ProfessionalLinkFull[]>>(`${url}/api/cp/professional`)
                    .pipe(map(res => _.map(res, p => Mapper.remapProfessionalLinkFull(p))))
            )
        );
    }

    getOwnProfessionalLink(serviceProviderId: number): Observable<ProfessionalLinkFull | null> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<ProfessionalLinkFull | null>
                    >(`${url}/api/cp/service-provider/${serviceProviderId}/professional/self`)
                    .pipe(map(res => Mapper.remapProfessionalLinkFull(res)))
            )
        );
    }

    addOwnProfessionalLink(
        serviceProviderId: number,
        personalAgbCode: string | null,
        importHistory: boolean,
        emrConnectionId: number | null
    ): Observable<AddOwnProfessionalLinkResult> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<AddOwnProfessionalLinkResult>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/self`,
                    { personalAgbCode, importHistory, emrConnectionId }
                )
            )
        );
    }

    getServiceProviders(): Observable<ServiceProviderContactPersonFull[]> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<ServiceProviderContactPersonFull[]>
                    >(`${url}/api/cp/service-provider`)
                    .pipe(
                        map(res => _.map(res, p => Mapper.remapServiceProviderContactPersonFull(p)))
                    )
            )
        );
    }

    // Note: this call doesn't check the validity of the code itself (against vektis registry)
    isServiceProviderAllowed(agbCode: string): Observable<IsServiceProviderAllowedResponse> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<IsServiceProviderAllowedResponse>(
                    `${url}/api/cp/service-provider/allowed/${agbCode}`
                )
            )
        );
    }

    getServiceProviderRelationsStatus(agbCode: string): Observable<ServiceProviderRelationsStatus> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<ServiceProviderRelationsStatus>(
                    `${url}/api/cp/service-provider/relations/status/${agbCode}`
                )
            )
        );
    }

    linkServiceProvider(args: {
        organisationAgbCode: string;
        specialArrangement: boolean;
        organisationName: string;
        organisationLine1: string;
        organisationZip: string;
        organisationCity: string;
        confirmationEmail: string;
        emrProviderIds: number[];
        surveyConnectionName: string | null;
    }): Observable<LinkServiceProviderResponse> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<LinkServiceProviderResponse>(
                    `${url}/api/cp/service-provider`,
                    args
                )
            )
        );
    }

    // Note: max 100 emails at once
    // Note: duplicate emails and emails aready linked are automatically ignored.
    // Note: invalid email address (failing validation) will fail the whole call
    inviteProfessionals(
        serviceProviderId: number,
        emails: string[]
    ): Observable<InviteProfessionalsResponse> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<InviteProfessionalsResponse>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/invite`,
                    emails
                )
            )
        );
    }

    setProfessionalStatus(
        serviceProviderId: number,
        userId: number,
        status: ProfessionalLinkLinkStatus
    ): Observable<SetProfessionalStatusResponseEntry> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<SetProfessionalStatusResponseEntry>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/${userId}/status`,
                    status
                )
            )
        );
    }

    setProfessionalStatusBatch(
        serviceProviderId: number,
        batch: _.NumericDictionary<ProfessionalLinkLinkStatus>
    ): Observable<_.NumericDictionary<SetProfessionalStatusResponseEntry>> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<_.NumericDictionary<SetProfessionalStatusResponseEntry>>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/status`,
                    batch
                )
            )
        );
    }

    addProfessionalEmrConnection(
        serviceProviderId: number,
        userId: number,
        emrConnectionId: number
    ): Observable<AddProfessionalEmrConnectionResult> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<AddProfessionalEmrConnectionResult>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/${userId}/emr`,
                    { emrConnectionId }
                )
            )
        );
    }

    addProfessionalEmrConnectionBatch(
        serviceProviderId: number,
        connections: _.NumericDictionary<number>
    ): Observable<_.NumericDictionary<AddProfessionalEmrConnectionResult>> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.post<_.NumericDictionary<AddProfessionalEmrConnectionResult>>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/professional/emr`,
                    connections
                )
            )
        );
    }

    getServiceProviderEmrConnections(): Observable<ServiceProviderEmrConnection[]>;
    getServiceProviderEmrConnections(
        includeDeleted: boolean
    ): Observable<ServiceProviderEmrConnection[]>;

    getServiceProviderEmrConnections(
        serviceProviderId: number,
        includeDeleted: boolean
    ): Observable<ServiceProviderEmrConnection[]>;

    getServiceProviderEmrConnections(
        serviceProviderId?: number | boolean,
        includeDeleted?: boolean
    ): Observable<ServiceProviderEmrConnection[]> {
        if (serviceProviderId === undefined || typeof serviceProviderId === "boolean") {
            return this._getUrl().pipe(
                switchMap(url =>
                    this._httpClient.get<ServiceProviderEmrConnection[]>(
                        `${url}/api/cp/emr?includeDeleted=${includeDeleted || false}`
                    )
                )
            );
        } else {
            return this._getUrl().pipe(
                switchMap(url =>
                    this._httpClient.get<ServiceProviderEmrConnection[]>(
                        `${url}/api/cp/service-provider/${serviceProviderId}/emr?includeDeleted=${
                            includeDeleted || false
                        }`
                    )
                )
            );
        }
    }

    getServiceProviderEmrConnection(
        serviceProviderId: number,
        id: number,
        includeDeleted = false
    ): Observable<ServiceProviderEmrConnectionFull | null> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient
                    .get<
                        JsonDates<ServiceProviderEmrConnectionFull | null>
                    >(`${url}/api/cp/service-provider/${serviceProviderId}/emr/${id}?includeDeleted=${includeDeleted}`)
                    .pipe(
                        map(result =>
                            result === null
                                ? null
                                : {
                                      ...result,
                                      serviceProvider: Mapper.remapServiceProvider(
                                          result.serviceProvider
                                      )
                                  }
                        )
                    )
            )
        );
    }

    addServiceProviderEmrConnection(
        serviceProviderId: number,
        emrProviderId: number,
        name: string
    ): Observable<AddServiceProviderEmrConnectionResponse> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient
                    .post<JsonDates<AddServiceProviderEmrConnectionResponse>>(
                        `${url}/api/cp/service-provider/${serviceProviderId}/emr`,
                        {
                            emrProviderId,
                            name
                        }
                    )
                    .pipe(
                        map(result => ({
                            ...result,
                            connection: result.connection
                                ? {
                                      ...result.connection,
                                      serviceProvider: Mapper.remapServiceProvider(
                                          result.connection.serviceProvider
                                      )
                                  }
                                : null
                        }))
                    )
            )
        );
    }

    updateServiceProviderEmrConnection(
        serviceProviderId: number,
        id: number,
        name: string
    ): Observable<UpdateServiceProviderEmrConnectionResult> {
        return this._getUrl().pipe(
            concatMap(url =>
                this._httpClient.patch<UpdateServiceProviderEmrConnectionResult>(
                    `${url}/api/cp/service-provider/${serviceProviderId}/emr`,
                    {
                        id,
                        name
                    }
                )
            )
        );
    }

    private _getUrl(): Observable<string> {
        return from(this._configuration).pipe(map(config => config.url));
    }
}
