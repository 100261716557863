export const enum Filters {
    // organization
    OrgStatus = "organizationStatus",
    Organization = "organization",

    // user
    User = "user",
    AgbCode = "agbCode",
    AgbName = "agbName",
    HpStatus = "hpStatus",
    Phone = "phone"
}
