import { Pipe, PipeTransform, inject } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "enumTranslate",
    pure: true
})
export class EnumTranslatePipe implements PipeTransform {
    private _translationService = inject(LgTranslateService);

    transform(value: number, enumName: string): any {
        if (value != null && enumName) {
            return this._translationService.translate(`APP._Enums.${enumName}.${value}`);
        }
        return value;
    }
}
