import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";

import { Auth0Guard } from "gli-authorization";

import { AccessDeniedComponent } from "./access-denied-page/access-denied.component";

import { AuthorizationGuard } from "./shared/services/authorization.guard";
import { DocusignFinishedComponent } from "./docusign/docusign-finished/docusign-finished.component";
import { DocusignCanceledComponent } from "./docusign/docusign-canceled/docusign-canceled.component";
import { DocusignComponent } from "./docusign/docusign/docusign.component";

const routes: Routes = [
    {
        path: "callback",
        redirectTo: "management",
    },

    {
        path: "management",
        loadChildren: () =>
            import("./authorized/authorized.module").then((m) => m.AuthorizedModule),
        canLoad: [Auth0Guard, AuthorizationGuard],
        canActivate: [Auth0Guard, AuthorizationGuard],
    },

    {
        path: "docusign",
        component: DocusignComponent,
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "/management",
            },
            {
                path: "finished/:id",
                component: DocusignFinishedComponent,
            },

            {
                path: "canceled/:id",
                component: DocusignCanceledComponent,
            },
            {
                path: "**",
                redirectTo: "/management",
            },
        ],
    },

    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },

    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },

    {
        path: "**",
        redirectTo: "management",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],

    exports: [RouterModule],
})
export class AppRoutingModule {}
