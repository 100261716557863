export interface User {
    name: string;
    email: string;
    applications: string[];
    organizations: string[];
    createdAt: Date;
    lastLogin: Date;
    userId?: string;
    selected?: boolean;
}
