import { ServiceProviderStatus } from "./service-provider";

export interface IsServiceProviderAllowedResponse {
    result:
        | "ok" // go ahead
        | "taken" // taken by someone else
        | "blocked" // blocked because of MRDM refusal, contact support
        | "linked" // already linked to you (check status property for details)
        | "cp-refused" // you've refused your link
        | "cp-removed"; // your link was removed;
    status: ServiceProviderStatus | null;
}
