import { Injectable, inject } from "@angular/core";
import { from, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { GLI_API_CONFIGURATION } from "./gli-api-configuration";
import { HttpClient } from "@angular/common/http";
import {
    EmailExistsResult,
    PersonalAgbCodeResult,
    OrganisationAgbCodeResult,
    HealthProviderResult
} from "./gli-query-types";
import { DocusignContractStatus } from "./models";

@Injectable({ providedIn: "root" })
export class GliQueryService {
    private _configuration = inject(GLI_API_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    getEmailExists(email: string): Observable<EmailExistsResult> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<EmailExistsResult>(`${url}/api/user/query/exists`, {
                    params: {
                        email
                    }
                })
            )
        );
    }

    getPersonalAgbCode(code: string): Observable<PersonalAgbCodeResult> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<PersonalAgbCodeResult>(`${url}/api/agb/personal/${code}`)
            )
        );
    }

    getOrganisationAgbCode(code: string): Observable<OrganisationAgbCodeResult> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<OrganisationAgbCodeResult>(
                    `${url}/api/agb/organisation/${code}`
                )
            )
        );
    }

    getOrganizationStatus(code: string): Observable<"taken" | "free"> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<"taken" | "free">(`${url}/api/service-provider/status`, {
                    params: { agbCode: code }
                })
            )
        );
    }

    getHealthProvider(email: string): Observable<HealthProviderResult> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<HealthProviderResult>(
                    `${url}/api/user/query/health-provider`,
                    {
                        params: { email }
                    }
                )
            )
        );
    }

    getHasWorkRelationToCompany(companyCode: string, personalCode: string): Observable<boolean> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<boolean>(
                    `${url}/api/agb/organisation/${companyCode}/has/work/${personalCode}`
                )
            )
        );
    }

    getDocusignContractStatus(
        envelopeId: string,
        recipientEmail: string
    ): Observable<DocusignContractStatus> {
        return this._getUrl().pipe(
            switchMap(url =>
                this._httpClient.get<DocusignContractStatus>(
                    `${url}/api/docusign/query/status/${envelopeId}`,
                    {
                        params: {
                            recipientEmail
                        }
                    }
                )
            )
        );
    }

    private _getUrl(): Observable<string> {
        return from(this._configuration).pipe(map(config => config.url));
    }
}
