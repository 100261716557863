export type DocusignContractStatus =
    // The contract was signed by someone else than CP
    | "forwarded"
    // The contract is still pending
    | "pending"
    // The contract is processed, this was self-employed user
    | "done-self-employed"
    | "done-self-employed-pending-emr"
    | "done-self-employed-emr"
    // The contract is processed
    | "done"
    // The contract is refused or voided
    | "refused";
