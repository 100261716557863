import {
    IInfoboxSpecification,
    LgExcelFactoryService,
    LogexXlsxApi
} from "@logex/framework/lg-exports";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgMonthNamePipe } from "@logex/framework/ui-core";

export class ExportBase {
    protected lgXlsx: LogexXlsxApi;
    protected _translationNamespace: string;

    constructor(
        protected logexPivot: any,
        lgXlsxService: LgExcelFactoryService,
        // protected appDefinitions: AppDefinitions,
        protected lgTranslate: LgTranslateService,
        // protected datePipe: DatePipe,
        protected monthNamePipe: LgMonthNamePipe
    ) {
        this.lgXlsx = lgXlsxService.create();
        this.lgXlsx.setDefaultStyle(this.lgXlsx.styles.logex);
        this.translate = this.translate.bind(this);
    }

    protected getDefaultInfobox(pageSettings: any): IInfoboxSpecification {
        return {
            row: 0,
            column: 0,
            spacing: 1,
            labelSpan: 1,
            textSpan: 1,
            textAlign: "right",
            info: [
                "Periode: ",
                () =>
                    `${this.monthNamePipe.transform(
                        pageSettings.range.from
                    )} - ${this.monthNamePipe.transform(pageSettings.range.to)}`
            ]
        };
    }

    protected translate(translationId: string, interpolateParams?: object): string {
        if (this._translationNamespace && translationId[0] === ".")
            translationId = this._translationNamespace + translationId;
        return this.lgTranslate.translate(translationId, interpolateParams);
    }

    protected getTranslateFn(): (translationId: string, interpolateParams?: any) => string {
        return this.translate;
    }

    protected translateFileNameWithCurrentTime(
        translationId: string,
        interpolationObject: object = {}
    ): string {
        return this.translate(translationId, {
            ...this._getFormattedDateForInterpolationObject(),
            ...interpolationObject
        });
    }

    private _getFormattedDateForInterpolationObject(): { date: string } {
        const now = new Date();

        return {
            date: now.toISOString().slice(0, 10).replace(/-/g, "")
        }; // https://stackoverflow.com/a/16714931
    }
}
