import { Component, OnInit, inject } from "@angular/core";
import { Auth0AuthorizationService } from "gli-authorization";

// export const UM_USER_INFO = new InjectionToken<UmUserInfo>("UM_USER_INFO")

@Component({
    selector: "um-sidebar-user",
    templateUrl: "./um-sidebar-user.component.html",
    host: {
        class: "lg-sidebar-account lg-sidebar-panel"
    }
})
export class UmSidebarComponent implements OnInit {
    private _auth0 = inject(Auth0AuthorizationService);

    _userName: string;
    _userEmail: string;
    _userPicture: string;

    ngOnInit(): void {
        this._auth0.getUser().subscribe(user => {
            this._userName = `${user.given_name} ${user.family_name}`;
            this._userEmail = user.email;
            this._userPicture = user.picture;
        });
    }

    _logout(): void {
        event.stopPropagation();
        event.preventDefault();
        this._auth0.logout();
    }
}
