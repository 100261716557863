import { AppUser } from "./app-user";
import { ServiceProvider } from "./service-provider";

export enum ServiceProviderContactPersonStatus {
    Created = 0,
    Accepted = 1,
    Refused = 2,
    Removed = 3
}

export interface ServiceProviderContactPerson {
    userId: number;
    serviceProviderId: number;
    status: ServiceProviderContactPersonStatus;
    created: Date;
    statusTimestamp: Date;
}

export interface ServiceProviderContactPersonFull extends ServiceProviderContactPerson {
    user: AppUser;
    serviceProvider: ServiceProvider;
}
