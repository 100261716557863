import { Component, ChangeDetectionStrategy } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

@Component({
    selector: "app-docusign",
    templateUrl: "./docusign.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [useTranslationNamespace("APP._Docusign")]
})
export class DocusignComponent {}
