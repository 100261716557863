export type EmailExistsResult = "yes" | "no" | "blocked" | "verify" | "federated";

export interface PersonalAgbCodeResult {
    type: "personal" | "company" | "branch" | "inactive";
    initials: string;
    lastName: string;
    gender: 1 | 2 | null;
    hasRequiredQualification: boolean;
}

export interface OrganisationAgbAddress {
    line1: string;
    zip: string;
    city: string;
    country: string;
    countryCode: string;
}

export type OrganisationAgbCodeResult =
    | {
          type: "personal";
      }
    | {
          type: "company" | "branch" | "inactive";
          name: string | null;
          tradeName: string | null;
          statutoryName: string | null;
          addresses: OrganisationAgbAddress[];
          emails: string[];
      };

export interface HealthProviderResult {
    agbCode: string;
    initials: string;
    lastName: string;
    gender?: 1 | 2;
    userFirstName: string | null;
    userLastName: string | null;
    userPhone: string | null;
    userPosition: string | null;
}
