import * as _ from "lodash";

export type AddProfessionalEmrConnectionResult =
    | "ok"
    | "not-found"
    | "already-approved"
    | "one-connection"
    | "already-exists"
    | "invalid-connection"
    | "failed";

export type AddProfessionalEmrConnectionBatchResponse =
    _.NumericDictionary<AddProfessionalEmrConnectionResult>;
